import "./SimpleCards.css";
import Image1 from "../Images/Spot_it_classic_2024_card.webp";
import Image2 from "../Images/Catan_card.webp";
import Image3 from "../Images/Pelusas_Card.webp";
import Image4 from "../Images/Mal_trago_card.webp";


function SimpleCards() {
  return (
    <div className="Top-Products row">
      <h2 className="top-headline">Lo más nuevo</h2>
      <div className="simple-card col" key={1}>
        <img
          src={Image1}
          className="product-image"
          alt="Spot it Classic 2024"
        />
        <h4 className="card-headline">Spot It Classic 2024</h4>
        <a href="/productos/1" className="card-button">
          Ver producto
        </a>
      </div>
      <div className="simple-card col" key={2}>
        <img
          src={Image2}
          className="product-image"
          alt="Catan Base Game"
        />
        <h4 className="card-headline">Catan</h4>
        <a href="/productos/20" className="card-button">
          Ver producto
        </a>
      </div>
      <div className="simple-card col" key={3}>
        <img src={Image3} className="product-image" alt="Pelusas juego de cartas" />
        <h4 className="card-headline">Pelusas</h4>
        <a href="/productos/5" className="card-button">
          Ver producto
        </a>
      </div>
      <div className="simple-card col" key={4}>
        <img src={Image4} className="product-image" alt="Mal Trabo Juego de mesa" />
        <h4 className="card-headline">Mal Trago</h4>
        <a href="/productos/4" className="card-button">
          Ver producto
        </a>
      </div>
    </div>
  );
}

export { SimpleCards };
