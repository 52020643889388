import React, { useState, useEffect } from 'react';
import './ImageSlider.css'; // Make sure to create this CSS file for styling
import Slide1 from '../Images/Slider_Intro.webp';
import Slide2 from '../Images/Slider2.webp';
import Slide3 from '../Images/Slider_Spot_It_Classic.webp';
import Slide4 from '../Images/Slider_Mal_Trago.webp';
import Slide5 from '../Images/Slider_Mente_Vacuna.webp';
import Slide6 from '../Images/Slider_Catan.webp';
import Slide7 from '../Images/Slider_Throw_Throw_Avocado.webp';
import SlideMobile1 from '../Images/Intro_mobile.webp';
import SlideMobile2 from '../Images/Spot_It_Classic_2024_mobile.webp';
import SlideMobile3 from '../Images/Catan_mobile.webp';
import SlideMobile4 from '../Images/Mal_Trago_mobile.webp';
import SlideMobile5 from '../Images/Mente_Vacuna_mobile.webp';
import SlideMobile6 from '../Images/Throw_Throw_Avocado_mobile.webp';

const imagesDesktop = [
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7
];

const imagesMobile = [
  SlideMobile1,
  SlideMobile2,
  SlideMobile3,
  SlideMobile4,
  SlideMobile5,
  SlideMobile6
];


export  function ImageSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Check if mobile view

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobile width threshold
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const images = isMobile ? imagesMobile : imagesDesktop; // Choose image set based on screen size

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Start fading out
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(false); // Start fading in
      }, 500); // Match this duration with CSS transition duration
    }, 8000); // Change image every 8 seconds

    return () => clearInterval(interval);
  }, [images]);

  const nextSlide = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setFade(false);
    }, 500); // Match this duration with CSS transition duration
  };

  const prevSlide = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setFade(false);
    }, 500); // Match this duration with CSS transition duration
  };

  return (
    <div className="slider-container">
       
      <button id='btn-slider' className="prev" onClick={prevSlide}>&lt;</button>
      <a href='/productos'> 
        <div className={`slider-wrapper ${fade ? 'fade' : ''}`}>
            <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="slider-image" />
        </div>
      </a>
      <button id='btn-slider' className="next" onClick={nextSlide}>&gt;</button>
    </div>
  );
};
